import { Button, ButtonArrow } from '@/components/common/button'
import { Carousel, CarouselContent, CarouselItem, CarouselViewport } from '@/components/common/carousel'
import { Container } from '@/components/common/container'
import { ExplorationIcon } from '@/components/icons/exploration'
import { IntegrationIcon } from '@/components/icons/integration'
import { Highlight, type HighlightProps } from '@/components/shared/highlight'
import { Testimonial, type TestimonialProps } from '@/components/shared/testimonial'
import { navigation } from '@/navigation'
import { useIntersectionObserver } from '@/utils/useIntersectionObserver'
import { Link } from '@remix-run/react'
import Autoplay from 'embla-carousel-autoplay'
import Fade from 'embla-carousel-fade'
import { useRef } from 'react'

const TESTIMONIALS: TestimonialProps[] = [
  {
    text: 'Qubelabs\'s professionalism and value-add to our partners and protocols is second-to-none',
    accentText: '— Anthony Beaumont, CEO and Co-Founder of Encode Club',
  },
  {
    text: 'Qubelabs have always been exceptional in validators duty, staying committed to the protocol since early days and facilitating community engagement. The web3 world would’ve achieved much more in faster timeframes if more people could’ve been like Qubelabs.',
    accentText: '— Nhu Viet Nguyen, Head of Devrel at Celestia Labs',
  },
  {
    text: 'The professionalism at Qubelabs shines through in every aspect of their work. They are reliable, responsive, and deliver quality results.',
    accentText: '— Michael Bentley, CEO and Co-Founder of Euler Labs',
  },
]

const POINTS: HighlightProps[] = [
  {
    icon: <ExplorationIcon />,
    title: 'Community Growth',
    text: 'We empower projects to build and scale thriving communities from the ground up. Through strategic community management, engaging programs, advanced analytics, and robust security solutions, we ensure sustainable growth. ',
  },
  {
    icon: <IntegrationIcon />,
    title: 'Network Development',
    text: 'We support network growth by offering scalable and secure infrastructure, including validation services, network tools, IBC relaying, and comprehensive documentation. Our goal is to ensure networks operate efficiently and effectively.',
  },
]

export const Testimonials = () => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useIntersectionObserver(ref, { threshold: 0.1 })
  const carouselPlugins = [Autoplay({ delay: 7_000, playOnInit: isVisible }), Fade()]

  return (
    <section ref={ref} id={navigation.testimonials} className="pb-20 md:pt-6 md:pb-32">
      <Container>
        <div className="flex w-full max-w-full flex-col items-center gap-12 overflow-hidden">
          <Carousel className="w-full max-w-full overflow-hidden" plugins={carouselPlugins}>
            <CarouselViewport>
              <CarouselContent>
                {TESTIMONIALS.map(({ text, accentText }, index) => (
                  <CarouselItem key={index} className="pb-1">
                    <Testimonial text={text} accentText={accentText} />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </CarouselViewport>
          </Carousel>
          <ul className="grid w-full flex-wrap gap-y-8 overflow-hidden md:grid-cols-2">
            {POINTS.map(({ icon, title, text }, index) => (
              <li
                key={`testimonial-point-${index}`}
                className="-ml-px -mt-px mr-px mb-px flex flex-grow border-light border-t border-l first:pl-0 last:pr-0 md:px-8"
              >
                <Highlight icon={icon} title={title} text={text} />
              </li>
            ))}
          </ul>
          <Button className="w-fit min-w-52" asChild>
            <Link to={`#${navigation.contact}`}>
              <span>Contact us</span>
              <ButtonArrow />
            </Link>
          </Button>
        </div>
      </Container>
    </section>
  )
}
